<template>
  <div>
    <b-col>
      <b-card class="bg-gradient-primary">
        <b-row align-v="center">
          <b-col>
            <h1>
              <feather-icon icon="GridIcon" size="30" class="mr-50" /> Galeria
              de Jogos
            </h1>
            <!-- <h4 >Inicie uma Aplicação de Jogo:</h4> -->
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col>
      <unity
        width="1000"
        height="600"
        unityLoader="/assets/Breakout_Web.loader.js"
        frameworkUrl="/assets/Breakout_Web.framework.js"
        dataUrl="/assets/Breakout_Web.data"
      ></unity>
    </b-col>

    <div>
      <div>
        <swiper
          class="swiper-centered-slides p-1"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <!-- slides -->
          <swiper-slide>
            <b-card
              class="card-consulta c-jogo_breakout"
              onclick="location.href='/NovaAplicacaodeJogo'"
            >
              <div class="img-jogo-sel">
                <img src="assets/images/modal_icons/logo_breakout.png" />
              </div>
              <h3 class="txt-jogo-sel mt-2">KNAPDOWN</h3>
            </b-card>
          </swiper-slide>

          <!-- Add Arrows -->
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import PacienteCard from '@/components/cards/PacienteCard.vue'
import AgendaCard from '@/components/cards/AgendaCard.vue'
import { BCard, BRow, BCol } from 'bootstrap-vue'

import Unity from 'vue-unity-webgl'

export default {
  components: {
    Swiper,
    SwiperSlide,
    PacienteCard,
    AgendaCard,
    BCard,
    BRow,
    BCol,
    Unity,
  },
  data: () => ({
    agenda: [
      {
        id: 1,
        tipo: 'Teste Cognitivo',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Aplicação do Jogo',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Jogo Teste',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
      {
        id: 1,
        tipo: 'Devolutiva',
        profissional: 'Marta Rogers',
        horario: '10:00-12:00',
        paciente: 'Henrique Guedes Formiga',
      },
    ],
    swiperData: [
      {
        id: 1,
        firstName: 'Henrique',
        lastName: 'Guedes Formiga',
        days: 30,
        percent: 30,
        better: true,
      },
      {
        id: 1,
        firstName: 'Henrique',
        lastName: 'Guedes Formiga',
        days: 7,
        percent: 4,
        better: false,
      },
      {
        id: 1,
        firstName: 'Henrique',
        lastName: 'Guedes Formiga',
        days: 30,
        percent: 30,
        better: true,
      },
    ],
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: false,
      breakpoints: {
        640: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
  }),
}
</script>
