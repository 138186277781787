var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-col', [_c('b-card', {
    staticClass: "bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "GridIcon",
      "size": "30"
    }
  }), _vm._v(" Galeria de Jogos ")], 1)])], 1)], 1)], 1), _c('b-col', [_c('unity', {
    attrs: {
      "width": "1000",
      "height": "600",
      "unityLoader": "/assets/Breakout_Web.loader.js",
      "frameworkUrl": "/assets/Breakout_Web.framework.js",
      "dataUrl": "/assets/Breakout_Web.data"
    }
  })], 1), _c('div', [_c('div', [_c('swiper', {
    staticClass: "swiper-centered-slides p-1",
    attrs: {
      "options": _vm.swiperOptions,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    }
  }, [_c('swiper-slide', [_c('b-card', {
    staticClass: "card-consulta c-jogo_breakout",
    attrs: {
      "onclick": "location.href='/NovaAplicacaodeJogo'"
    }
  }, [_c('div', {
    staticClass: "img-jogo-sel"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/logo_breakout.png"
    }
  })]), _c('h3', {
    staticClass: "txt-jogo-sel mt-2"
  }, [_vm._v("KNAPDOWN")])])], 1), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }